'use strict';

angular.module('enervexSalesappApp').service('AccountRequestsService', function(AccountRequest, AccountRequestComment, $stateParams){
	this.approveRequest = approveRequest;
	this.denyRequest = denyRequest;
	this.getComments = getComments;
	this.makeComment = makeComment;
	this.removeComment = removeComment;

	function approveRequest(){
		return AccountRequest.approve({
			accountRequestId: $stateParams.accountRequestId,
			accountId: $stateParams.accountId,
		});
	}

	function denyRequest(){
		return AccountRequest.deny({
			accountRequestId: $stateParams.accountRequestId,
			accountId: $stateParams.accountId,
		});
	}

	function getComments(){
		return AccountRequestComment.query({
			accountRequestId: $stateParams.accountRequestId,
			accountId: $stateParams.accountId,
		});
	}

	function makeComment(comment){
		return AccountRequestComment.save({
			accountId: $stateParams.accountId,
			accountRequestId: $stateParams.accountRequestId,
		}, comment);
	}

	function removeComment(comment){
		return AccountRequestComment.remove({
			accountId: $stateParams.accountId,
			accountRequestId: $stateParams.accountRequestId,
			id: comment._id
		});
	}
	this.updateComment = function(comment){
		return AccountRequestComment.update({
			accountId: $stateParams.accountId,
			accountRequestId: $stateParams.accountRequestId,
			id: comment._id
		}, comment);
	}
});
